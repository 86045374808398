// Text Styles Guide eBarn
.headline-3 {
  font-size: 3rem;
  line-height: 1.2;
  font-weight: 400;

  color: var(--color-primary-default);
}

.headline-4 {
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 400;

  color: var(--color-primary-default);
}

.headline-5 {
  font-size: 1.5rem;
  line-height: 1.2;
  font-weight: 400;

  color: var(--color-primary-default);
}

.headline-6 {
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 500;

  color: var(--color-primary-default);
}

.subtitle-1 {
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 400;

  color: var(--color-gray-7);
}

.subtitle-2 {
  font-size: 0.875rem;
  line-height: 1.2;
  font-weight: 500;

  color: var(--color-gray-7);
}

.body-1 {
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 400;

  color: var(--color-gray-7);
}

.body-2 {
  font-size: 0.875rem;
  line-height: 1.2;
  font-weight: 400;

  color: var(--color-gray-7);
}

.caption {
  font-size: 0.75rem;
  line-height: 1.2;
  font-weight: 400;

  color: var(--color-gray-7);
}

.overline {
  font-size: 0.625rem;
  line-height: 1.2;
  font-weight: 400;
  text-transform: uppercase;

  color: var(--color-foreground-text);
}
