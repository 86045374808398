ebarn-card-artigo {
  $icon-size: rem(64);

  display: block;

  background-color: $white-light;
  border-radius: rem(32);
  position: relative;

  max-width: 450px;
  margin-top: calc(#{$icon-size} / 2);
  height: calc(100% - #{$icon-size} / 2);

  @include shadow-green-1();

  > mat-icon.mat-icon {
    width: $icon-size;
    height: $icon-size;
    font-size: $icon-size;

    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);

    color: $primary-normal;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    padding: 1rem 1.5rem;
    padding-top: calc(0.5rem + #{$icon-size} / 2);
    height: 100%;

    h5 {
      color: $gray-1;
      text-align: center;
      text-transform: uppercase;
    }

    p {
      margin-top: 1rem;
    }

    .button {
      @include gradient(1);
      @include body(p3);
      background-clip: text;
      -webkit-text-fill-color: transparent;

      margin-top: 0.5rem;
      align-self: flex-end;
      font-weight: 500;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }
}
