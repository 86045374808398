ebarn-toolbar {
  .toolbar-content {
    max-width: $container-lt-lg;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mobile {
    .logo-ebarn {
      position: absolute;
      width: rem(32);
      height: rem(32);

      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);
    }

    .cadastro {
      @include body(p2, mobile);
      color: $black-light;
      text-decoration: none;
    }
  }

  .content-1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .logo-ebarn {
    padding-top: 0.25rem;
    margin-right: 1rem;

    width: rem(40);
    height: rem(40);
  }

  button.mat-mdc-icon-button mat-icon {
    width: rem(32);
    height: rem(32);
    font-size: rem(32);

    color: $primary-normal;
  }

  .ebarn-menu {
    ul {
      list-style: none;

      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    li:not(:last-child) {
      margin-right: 1rem;
    }

    a {
      @include body(p2, mobile);
      position: relative;
      padding: 12px 16px;
      display: inline-block;
      color: $black-light;
      text-decoration: none;

      &:active {
        color: $black-light;
      }

      &:focus {
        outline: none;
      }

      &:after {
        content: '';
        position: absolute;
        top: 90%;
        left: 0;

        width: 0%;
        height: 2px;

        transition: 0.5s ease;

        background-color: $secondary-light;
      }

      &:hover {
        opacity: 0.7;
        cursor: pointer;

        &:after {
          width: 90%;
        }
      }
    }
  }
}
