@mixin shadow-green-1() {
  box-shadow: 0px 51px 80px rgba(82, 187, 144, 0.08),
    0px 15.375px 24.1177px rgba(82, 187, 144, 0.0521271),
    0px 6.38599px 10.0172px rgba(82, 187, 144, 0.04),
    0px 2.30969px 3.62304px rgba(82, 187, 144, 0.0278729);
}

@mixin shadow-green-2() {
  box-shadow: 0px 4px 92px rgba(82, 187, 144, 0.1),
    0px 2.46675px 53.3343px rgba(82, 187, 144, 0.0738656),
    0px 2.02606px 38.9688px rgba(82, 187, 144, 0.0639209),
    0px 1.82538px 31.2486px rgba(82, 187, 144, 0.0561634),
    0px 1.63828px 25.5109px rgba(82, 187, 144, 0.0474941),
    0px 1.25156px 18.0994px rgba(82, 187, 144, 0.0343964);
}

.shadow-green-1 {
  box-shadow: 0px 51px 80px rgba(82, 187, 144, 0.08),
    0px 15.375px 24.1177px rgba(82, 187, 144, 0.0521271),
    0px 6.38599px 10.0172px rgba(82, 187, 144, 0.04),
    0px 2.30969px 3.62304px rgba(82, 187, 144, 0.0278729);
}

.shadow-green-2 {
  box-shadow: 0px 4px 92px rgba(82, 187, 144, 0.1),
    0px 2.46675px 53.3343px rgba(82, 187, 144, 0.0738656),
    0px 2.02606px 38.9688px rgba(82, 187, 144, 0.0639209),
    0px 1.82538px 31.2486px rgba(82, 187, 144, 0.0561634),
    0px 1.63828px 25.5109px rgba(82, 187, 144, 0.0474941),
    0px 1.25156px 18.0994px rgba(82, 187, 144, 0.0343964);
}
