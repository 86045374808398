@import '@angular/flex-layout/mq';

@import 'variables_theme';

@import 'app/theme/styles/variables';
@import 'app/theme/styles/mixins';

@import 'app/theme/styles/base';
@import 'app/theme/styles/spaces';
@import 'app/theme/styles/gradients';
@import 'app/theme/styles/theme-reset';
@import 'app/theme/styles/theme';
@import 'app/theme/styles/typography';

@import 'app/shared/scss/main';

.mat-mdc-chip-action-label {
  color: white !important;
}

.ebarn-dialog {
  .mat-mdc-dialog-container {
    border-radius: 1rem;
    max-height: 100vh;
    //background: var(--color-gray-1) !important;
  }

  .mat-mdc-dialog-content {
    overflow: hidden;
  }
}

.ebarn-background-menu,
.ebarn-background-modal {
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: grayscale(1) blur(2px);
  border-radius: 0px;
  opacity: 1 !important;
}

.my-panel {
  border-radius: 15px 15px 0 0;
}

.bottom-sheet-full {
  height: 100vh;
  width: 100vw;

  .mat-bottom-sheet-container {
    width: 100% !important;
    height: 100% !important;
    max-height: 100vh !important;
    max-width: 100vw !important;
  }

  .mat-horizontal-stepper-content {
    height: 100%;
  }
}

.bottom-sheet-total-height {
  height: 80vh;

  .mat-horizontal-stepper-content {
    height: 100%;
  }
}

mat-form-field.no-padding {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {
    border-radius: 20px !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-flex {
    border-radius: 30px !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-underline {
    display: none !important;
  }
}

.ebarn-btn-shadow {
  border-radius: 30px !important;
  box-shadow: rgb(0 0 0 / 16%) 0px 10px 36px 0px, rgb(0 0 0 / 6%) 0px 0px 0px 1px !important;
}

.magic-hover {
  transition: all 0.1s ease;
}
.magic-hover:hover {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px;
  margin: 2px;
  padding: 5px;
}

.hover-green:hover {
  border: 1px solid #189e67;
}

.hover-white:hover {
  border: 1px solid white;
}

.cursor-edit {
  cursor: auto;
}
.cursor-move {
  cursor: all-scroll !important;
}
.btn-custom {
  color: #fff !important;
  padding-bottom: 2px;
  padding-bottom: 2px;
  margin-right: 5px !important;
  opacity: 0.75;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s ease;
}
.btn-blue {
  padding: 3px 5px;
  border: none;
  border-radius: 5px;
  outline: none;
  background-color: darkslateblue;
  cursor: pointer;
}

.btn-blue-vissi {
  border: none;
  outline: none;
  background-color: darkslateblue !important;
  cursor: pointer;
}
.btn-custom:hover {
  opacity: 1;
}
.btn-red-delete {
  background-color: red !important;
  border: 1px solid darkred;
}
.btn-green-clone {
  background-color: #189e67 !important;
  border: 1px solid green;
}

.box-items-floating-actions {
  display: none;
  flex-direction: row;
  position: absolute;
  top: 15px;
  right: 15px;
}

.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
  border-radius: 30px !important;
  .banner-login {
    .item {
      border-radius: 20px !important;
    }
    .banner {
      width: 100% !important;
    }
  }
}

mat-form-field.no-padding {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {
    border-radius: 20px !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-flex {
    border-radius: 30px !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-underline {
    display: none !important;
  }
}

/* Stepper sem mat-step-header */
mat-horizontal-stepper.ebarn-stepper {
  .mat-horizontal-stepper-header-container {
    display: none;
  }

  .mat-horizontal-content-container {
    height: 100%;
    padding: 0;
  }
}

.standard-tag {
  background-color: #189e67 !important;
  color: #fff;
  height: 18px;
  font-size: 12px;
  border-radius: 15px;
  padding: 3px 10px;
  position: absolute;
  top: 12px;
  right: 16px;
}

.simple-tag {
  background-color: #189e67 !important;
  color: #fff;
  height: 18px;
  font-size: 12px;
  border-radius: 15px;
  padding: 3px 10px;
}

.standard-chip {
  color: #fff !important;
  padding: 17px 20px !important;
  font-size: 10px !important;
  min-height: 10px !important;
  border-radius: 30px !important;
}
.simple-title {
  color: gray;
  font-size: 9px;
}

.blue {
  background-color: rgb(152, 152, 209) !important;
}

.stepper-inicial {
  .mat-horizontal-stepper-header-container {
    display: none !important;
  }
  .mat-horizontal-content-container {
    padding: 0 !important;
  }
  .instrucoes-component {
    border: none !important;
    box-shadow: none !important;
    padding: 0 !important;
    padding-bottom: 20px !important;
  }
  .instrucoes-external-content {
    height: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    align-items: center;
    padding: 0 10vw;
    flex: 1;
  }
  .instrucoes-actions {
    padding: 30px 0;
  }
}

.bold {
  font-weight: bold;
}

.box-smooth-horizontal-global {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 70px;
  background-image: -webkit-linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 1) 100%
  );
  background-image: -moz-linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 1) 100%
  );
  background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 100%);
  background-position: 50% 50%;
  -webkit-background-origin: padding-box;
  background-origin: padding-box;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-background-size: auto auto;
  background-size: auto auto;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.filter-lead-order-by .mat-form-field-wrapper {
  padding-bottom: 0%;
}

.radio-group-motivo {
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  .mat-radio-label-content {
    white-space: normal;
  }
}

.btn-continuar {
  text-align: center !important;
  text-decoration: none !important;
  cursor: pointer !important;
  border-radius: 100px !important;
  width: 80vw !important;
  max-width: 600px !important;
  padding: 0 10px !important;
  margin: 0 10px !important;
  display: flex !important;
  color: #fff !important;
  font-size: 15px !important;
  font-weight: bold !important;
  flex-direction: row !important;
  justify-content: center !important;
  outline: none !important;
  border: none !important;
  align-items: center !important;
  transition: all 0.1s ease !important;
}

.btn-continuar:hover {
  background-color: rgb(0, 226, 132);
  box-shadow: 0px 7px 11.48px 2.52px rgba(0, 0, 0, 0.11), inset 0px -5px 0px 0px rgba(0, 0, 0, 0.08);
}

.checkboxes {
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-layout .mat-checkbox-label {
    white-space: pre-wrap !important;
    line-height: 15px !important;
    font-size: 14px !important;
  }
}

.rounded-icon-bg-ebarn {
  border-radius: 50px !important;
  background-color: #d3d3d3;
  margin: 0 auto;
  width: 50px;
  .icon-svg {
    width: 30px;
    margin: 7px;
  }
}

.card-standard-ebarn {
  //box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07),    0 32px 64px rgba(0, 0, 0, 0.07) !important;
  box-shadow: 0 35px 45px rgb(0 0 0 / 5%), 0 15px 15px rgb(0 0 0 / 1%) !important;
  border: none !important;
  padding: none !important;
  border-radius: 30px !important;
}

mat-form-field.no-padding {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {
    border-radius: 20px !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-flex {
    border-radius: 30px !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-underline {
    display: none !important;
  }
}

.app {
  .ebarn-gerenciadores {
    h1 {
      color: #189e67;
    }

    p {
      color: #67666b;
    }

    .destaque-verde-padrao {
      color: #189e67;
    }

    .destaque-cinza-padrao {
      color: #67666b;
    }

    .destaque-verde-fluorescente {
      color: #48aa4d;
    }

    .destaque-verde-claro {
      color: #a4cebd;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
    mat-card.mat-mdc-card:not([class*='mat-elevation-z']) {
      border-radius: 30px;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 19px;
    }

    .mat-toolbar {
      color: #189e67;
    }

    .mat-drawer-container {
      background-color: #f7f7f7;
    }

    .mat-bottom-sheet-container {
      border-radius: 20px 20px 0 0;
    }
  }
}

.container-botoes {
  background-color: #f1f1f1;
  width: 100%;
  padding: 20px 0;

  .title-botoes {
    margin-bottom: 15px;
  }
}

.card-novo-primeiro {
  background-color: white;
  height: 210px;
  width: 165px;
  margin: 0 8px;
  cursor: not-allowed;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);

  span {
    position: absolute;
    top: 150px;
    padding: 0 5px;
    border-radius: 15px;
    background-color: rgba(9, 53, 173, 0.226);
  }
}

.credits {
  align-items: center;
  font-size: 13px;
  margin: 5px auto;
  a {
    color: black;
    text-decoration: none;
    img {
      max-width: 20px;
    }
  }
  a:active {
    color: black;
    text-decoration: none;
  }
}

.full-screen-modal {
  @media (max-width: 720px) {
    width: 100%;
    min-width: 100vw !important;
    min-height: 100vh !important;
  }
}

.mat-tag {
  background: var(--primary-light) !important;
  max-height: 5px !important;
  transition: 0.2s;

  &:hover {
    filter: brightness(1.2);
  }
}

// MAT-DATETIMEPICKER
.mat-datetimepicker-calendar-header {
  background-color: var(--color-primary-default);
}
.mat-datetimepicker-calendar-body-cell-content.mat-datetimepicker-calendar-body-selected {
  background-color: var(--color-primary-default);
  color: white;
}
.mat-datetimepicker-clock-hand {
  background-color: var(--color-primary-default);
}
.mat-datetimepicker-clock-center {
  background-color: var(--color-primary-default);
}
.mat-datetimepicker-clock-cell.mat-datetimepicker-clock-cell:hover {
  background-color: var(--color-primary-default);
}
.mat-datetimepicker-clock-cell.mat-datetimepicker-clock-cell-selected {
  background-color: var(--color-primary-default);
  // ckeditor image styling
}

:root {
  --ck-image-style-spacing: 1.5em;
  --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
}

.image {
  margin-left: auto;
  margin-right: auto;
}

.image-style-side {
  float: right;
  margin-left: var(--ck-image-style-spacing);
  max-width: 50%;
}

#dropzone-input,
.dropzone-input {
  opacity: 0;
  width: 520px;
  max-width: 85%;
  height: 100px;
  z-index: 2;
  position: absolute;
  cursor: pointer;
}

.dropzone-wrapper {
  position: relative;
  span {
    align-self: flex-start;
  }
}

#dropzone {
  background: #f5f8fa;
  border: 1px dashed var(--primary-dark);
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 14px;
  width: 100%;
  height: 100px;

  transition: 0.2s;

  &:hover {
    border: 1px dashed var(--primary-light);
    cursor: pointer;
  }

  .plus {
    color: #15b6d6;
  }
}

.preview {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  width: 100%;
  padding: 5px;
  border-radius: 8px;

  background: #f5f8fa;
  border: 1px dashed var(--primary-dark);
  box-sizing: border-box;

  img {
    border-radius: 8px;
    height: 100%;
    width: 30%;
  }

  small {
    color: var(--primary-dark);
  }

  .btn-close {
    color: var(--red-dark);
    transition: 0.2s;

    &:hover {
      cursor: pointer;
      color: var(--red-normal);
    }
  }
}

.custom-cta {
  background: var(--color-customer-accent-default);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  max-width: 160px;
  text-decoration: none;

  span {
    color: var(--white-light);
    font-size: 24px;
    font-weight: 700;
  }
}

.flex-p {
  display: flex;
  flex-direction: row;
  gap: 20px;

  a {
    width: 30px;
    height: 30px;

    img {
      width: 100% !important;
      height: 100% !important;
      min-width: 30px !important;
      min-height: 30px !important;
    }
  }
}

.empty-list-message {
  border: 2px solid var(--secondary);
  background-color: #424242;
  border-radius: 8px;
  color: var(--white-normal);
  padding: 15px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-self: center;
  justify-self: center;
  align-items: center;
  justify-content: center;

  span {
    font-weight: 700;
    margin-bottom: 10px;
  }
}

.full-screen-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 130px);
}
