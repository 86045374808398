button.btn-ebarn {
  text-decoration: none;
  padding: 0.5rem 1.75rem;
  background: $white-light;

  color: $black-normal;

  border: none;
  border-radius: rem(32);

  cursor: pointer;
  transition: 0.3s ease;

  @include body(p3);
  @include layout-bp(lt-sm) {
    @include body(p3, mobile);
  }

  &:active {
    opacity: 0.8;
  }

  &:hover {
    transform: scale(1.02);
  }

  &:focus {
    outline: none;
  }
}

button.btn-ebarn-gradient {
  @include gradient(1);
  color: $white-normal;
}

button.btn-ebarn-transparent {
  background: transparent;
  color: $black-normal;
}
