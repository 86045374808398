$primary: (
  normal: #189e67,
  dark: #118656,
  light: #52bb90
);

$secondary: (
  normal: #48aa4d,
  dark: #3a903f,
  light: #76c37a
);

$black: (
  normal: #1d211f,
  dark: #111714,
  light: #292e2c
);

$white: (
  normal: #f2faf7,
  dark: #fcfffe,
  light: #fbfffd
);

$gray: (
  1: #454446,
  2: #555456,
  3: #666566,
  4: #6b6a6b,
  5: #868587,
  6: #969597,
  7: #a6a6a7
);

$red: (
  normal: #eb3d3d,
  dark: #d03333,
  light: #f05454
);

$green: (
  normal: #32d957,
  dark: #28bb49,
  light: #50e170
);

$yellow: (
  normal: #ffce52,
  dark: #d9b043,
  light: #ffd76b
);

$primary-normal: map-get($primary, normal);
$primary-dark: map-get($primary, dark);
$primary-light: map-get($primary, light);

$secondary-normal: map-get($secondary, normal);
$secondary-dark: map-get($secondary, dark);
$secondary-light: map-get($secondary, light);

$black-normal: map-get($black, normal);
$black-dark: map-get($black, dark);
$black-light: map-get($black, light);

$white-normal: map-get($white, normal);
$white-dark: map-get($white, dark);
$white-light: map-get($white, light);

$gray-1: map-get($gray, 1);
$gray-2: map-get($gray, 2);
$gray-3: map-get($gray, 3);
$gray-4: map-get($gray, 4);
$gray-5: map-get($gray, 5);
$gray-6: map-get($gray, 6);
$gray-7: map-get($gray, 7);

$red-normal: map-get($red, normal);
$red-dark: map-get($red, dark);
$red-light: map-get($red, light);

$green-normal: map-get($green, normal);
$green-dark: map-get($green, dark);
$green-light: map-get($green, light);

$yellow-normal: map-get($yellow, normal);
$yellow-dark: map-get($yellow, dark);
$yellow-light: map-get($yellow, light);

@mixin map-to-css-var($map, $prefix-colors) {
  @each $name, $color in $map {
    --#{$prefix-colors}-#{$name}: #{$color};
  }
}

:root {
  @include map-to-css-var($primary, 'primary');
  @include map-to-css-var($secondary, 'secondary');
  @include map-to-css-var($black, 'black');
  @include map-to-css-var($white, 'white');
  @include map-to-css-var($gray, 'gray');
  @include map-to-css-var($red, 'red');
  @include map-to-css-var($green, 'green');
  @include map-to-css-var($yellow, 'yellow');
}
