$container-lt-sm: 599px;
$container-lt-md: 959px;
$container-lt-lg: 1279px;
$container-lt-xl: 1919px;

.container {
  max-width: $container-lt-lg;
  margin: 0 auto;
  // padding-left: 1.5rem;
  // padding-right: 1.5rem;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;

  @include layout-bp(lt-sm) {
    flex-direction: column;

    padding-left: rem(18);
    padding-right: rem(18);
  }
}

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.flex-item-1 {
  flex: 1 1 360px;
}

.flex-item-2 {
  flex: 2 1 360px;
}

.flex-item-3 {
  flex: 3 1 360px;
}

.flex-item-4 {
  flex: 4 1 360px;
}

.flex-item-5 {
  flex: 5 1 360px;
}

.flex-auto {
  flex: 1 1 auto;
}
