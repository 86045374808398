@use '@angular/material' as mat;
@include mat.core();

// Configura fonte
$typography: mat.define-typography-config($font-family);
@include mat.typography-hierarchy($typography);

$color-accent: (
  100: #c4e8ca,
  200: #9dd9a9,
  500: #2bb552,
  800: #189e67,
  900: #00631e,
  contrast: map-get(mat.$green-palette, contrast)
);

$primary: mat.define-palette(mat.$grey-palette, 800, 500, 900);
$accent: mat.define-palette($color-accent, 800, 200, 900);
$warn: mat.define-palette(mat.$red-palette, A200);

$theme: mat.define-light-theme((color: (primary: $primary, accent: $accent, warn: $warn), typography: $typography, density: 0));

// Configura tema
@include mat.all-component-themes($theme);
@include theme-reset($theme);

// Configura variaveis css
:root {
  @include theme-to-css-var($theme);

  @include map-colors-to-css-var($customer-primary, 'customer-primary');
  @include map-colors-to-css-var($customer-accent, 'customer-accent');

  --color-error-default: #f44336;
  --color-success-default: #009940;

  --color-error-lighter: #ffd9d9;
  --color-error-darker: #ba000d;
  --color-warning-default: #e18e2a;
  --color-warning-lighter: #ffefc7;
  --color-warning-darker: #b26a12;
  --color-success-lighter: #b8e5a1;
  --color-success-darker: #006913;
  --color-success-selection: #c2e5d8;
  --color-info-default: #4eabe9;
  --color-info-lighter: #cfd8ee;
  --color-info-darker: #193580;

  // Escala de cinza - Style Guide eBarn
  --color-gray-1: #fcfcff;
  --color-gray-2: #f0f0f2;
  --color-gray-3: #d7d7d9;
  --color-gray-4: #bdbebf;
  --color-gray-5: #a4a4a6;
  --color-gray-6: #8b8b8c;
  --color-gray-7: #727273;
  --color-gray-8: #585859;
  --color-gray-9: #3f3f40;
  --color-gray-10: #262626;
  --color-gray-11: #0d0d0d;
}
