@mixin head($size, $device: desktop) {
  font-family: 'Ubuntu', sans-serif;

  // H1
  @if $size == h1 and $device == desktop {
    font-size: rem(64);
    line-height: 110%;
    font-weight: bold;
  } @else if ($size == h1 and $device == mobile) {
    font-size: rem(36);
    line-height: 120%;
    font-weight: bold;
  }

  // H2
  @else if $size == h2 and $device == desktop {
    font-size: rem(48);
    line-height: 110%;
    font-weight: normal;
  } @else if ($size == h2 and $device == mobile) {
    font-size: rem(36);
    line-height: 120%;
    font-weight: normal;
  }

  // H3
  @else if $size == h3 and $device == desktop {
    font-size: rem(36);
    line-height: 110%;
    font-weight: lighter;
  } @else if ($size == h3 and $device == mobile) {
    font-size: rem(24);
    line-height: 120%;
    font-weight: lighter;
  }

  // H4
  @else if $size == h4 and $device == desktop {
    font-size: rem(24);
    line-height: 110%;
    font-weight: bold;
  } @else if ($size == h4 and $device == mobile) {
    font-size: rem(16);
    line-height: 110%;
    font-weight: bold;
  }

  // H5
  @else if $size == h5 and $device == desktop {
    font-size: rem(24);
    line-height: 110%;
    font-weight: normal;
  } @else if ($size == h5 and $device == mobile) {
    font-size: rem(16);
    line-height: 110%;
    font-weight: normal;
  }
}

@mixin body($size: p2, $device: desktop) {
  font-family: 'Ubuntu', sans-serif;

  // P1
  @if $size == p1 and $device == desktop {
    font-size: rem(24);
    line-height: 140%;
    font-weight: normal;
  } @else if ($size == p1 and $device == mobile) {
    font-size: rem(18);
    line-height: 130%;
    font-weight: normal;
  }

  // P2
  @else if $size == p2 and $device == desktop {
    font-size: rem(18);
    line-height: 150%;
    font-weight: normal;
  } @else if ($size == p2 and $device == mobile) {
    font-size: rem(16);
    line-height: 150%;
    font-weight: normal;
  }

  // P3
  @else if $size == p3 and $device == desktop {
    font-size: rem(16);
    line-height: 150%;
    font-weight: normal;
  } @else if ($size == p3 and $device == mobile) {
    font-size: rem(14);
    line-height: 150%;
    font-weight: normal;
  }
}

.h1 {
  color: $black-light;
  @include head(h1, desktop);

  @include layout-bp(lt-sm) {
    @include head(h1, mobile);
  }
}

.h2 {
  color: $black-light;
  @include head(h2, desktop);

  @include layout-bp(lt-sm) {
    @include head(h2, mobile);
  }
}

.h3 {
  color: $black-light;
  @include head(h3, desktop);

  @include layout-bp(lt-sm) {
    @include head(h3, mobile);
  }
}

.h4 {
  text-transform: uppercase;
  @include gradient(1);
  background-clip: text;
  -webkit-text-fill-color: transparent;

  @include head(h4, desktop);

  @include layout-bp(lt-sm) {
    @include head(h4, mobile);
  }
}

.h5 {
  @include head(h4, desktop);

  @include layout-bp(lt-sm) {
    @include head(h4, mobile);
  }
}

.body-1 {
  color: $gray-3;

  @include body(p1, desktop);

  @include layout-bp(lt-sm) {
    @include body(p1, mobile);
  }
}

.body-2 {
  color: $gray-3;

  @include body(p2, desktop);

  @include layout-bp(lt-sm) {
    @include body(p2, mobile);
  }
}

.body-3 {
  color: $gray-3;

  @include body(p3, desktop);

  @include layout-bp(lt-sm) {
    @include body(p3, mobile);
  }
}
