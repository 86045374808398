@mixin menu-level-padding($direction) {
  $elem: '';
  @for $i from 2 through 10 {
    $elem: if($i == 2, '.sub-menu', selector-nest($elem, '.sub-menu'));
    #{$elem + ' .mat-mdc-button'} {
      @if $direction == 'rtl' {
        padding-right: 20px * $i;
      } @else {
        padding-left: 20px * $i;
      }
    }
  }
}

@mixin theme-to-css-var($theme) {
  $types: ('primary', 'accent', 'warn', 'foreground', 'background');

  @each $type in $types {
    @include map-colors-to-css-var(map-get($theme, $type), $type);
  }
}

@mixin map-colors-to-css-var($map, $prefix-colors) {
  @each $name, $color in $map {
    @if $name != 'contrast' {
      --color-#{$prefix-colors}-#{$name}: #{$color};
    }
  }
}
