ebarn-card-depoimento {
  $imagem-size: rem(72);

  display: block;

  background-color: $white-light;
  border-radius: rem(32);
  position: relative;

  margin-top: calc(#{$imagem-size} / 2);
  height: calc(100% - #{$imagem-size} / 2);

  // @include shadow-green-1();

  .content {
    width: 100%;
    height: 100%;
    padding: 1.5rem;
    padding-top: calc(2rem + #{$imagem-size} / 2);
    padding-left: 4rem;
  }

  div.imagem {
    width: $imagem-size;
    height: $imagem-size;
    border-radius: 50%;

    position: absolute;
    left: 2rem;
    transform: translateY(-50%);
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }

    @include shadow-green-1();
  }

  span {
    font-size: rem(72);
    color: var(--gray-1);

    position: absolute;
    transform: translate(-110%, -40%);
  }

  p.body-3 {
    color: var(--gray-4);
  }

  p.body-2 {
    margin-top: 0.5rem;
    color: var(--gray-1);
    text-align: right;
  }
}
