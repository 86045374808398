$gradientes: (
  1: linear-gradient(220.94deg, $secondary-normal 14.43%, $primary-normal 85.28%),
  2: linear-gradient(225deg, $primary-normal 0%, $secondary-normal 100%),
  3: linear-gradient(90deg, $primary-normal 0%, $secondary-normal 100%),
  4: linear-gradient(270deg, $primary-normal 0%, $secondary-normal 100%),
  5: linear-gradient(180deg, $primary-normal 0%, $secondary-normal 100%),
  6: linear-gradient(0deg, $primary-normal 0%, $secondary-normal 100%),
  7: linear-gradient(53.58deg, $primary-normal -24.15%, $secondary-normal 134.04%),
  8: linear-gradient(225deg, $primary-normal 0%, rgba(24, 158, 103, 0) 100%),
  9: linear-gradient(90deg, $primary-normal 0%, rgba(24, 158, 103, 0) 100%),
  10: linear-gradient(270deg, $primary-normal 0%, rgba(24, 158, 103, 0) 100%),
  11: linear-gradient(180deg, $primary-normal 0%, rgba(24, 158, 103, 0) 100%),
  12: linear-gradient(0deg, $primary-normal 0%, rgba(24, 158, 103, 0) 100%)
);

@mixin gradient($chave) {
  @if (map-has-key($gradientes, $chave)) {
    background: map-get($gradientes, $chave);
  }
}

@each $key, $gradient in $gradientes {
  .gradient-#{$key} {
    background: $gradient;
  }
}
