ebarn-card-icone {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  background-color: $white-light;
  @include shadow-green-1();

  &.pequeno {
    width: rem(64);
    height: rem(64);

    border-radius: 1.25rem;

    mat-icon {
      width: rem(32);
      height: rem(32);
    }
  }

  &.medio {
    width: rem(96);
    height: rem(96);

    border-radius: 1.75rem;

    mat-icon {
      width: rem(48);
      height: rem(48);
    }
  }

  &.largo {
    width: rem(128);
    height: rem(128);

    border-radius: 2rem;

    mat-icon {
      width: rem(72);
      height: rem(72);
    }
  }

  &.extra-largo {
    width: rem(180);
    height: rem(180);

    border-radius: 3.5rem;

    mat-icon {
      width: rem(85);
      height: rem(85);
    }
  }

  &.shadow-1 {
    @include shadow-green-1();
  }

  &.shadow-2 {
    @include shadow-green-2();
  }
}
