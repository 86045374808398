.btn-loja {
  text-decoration: none;

  button {
    width: rem(170);
    padding: 0.5rem 1.5rem;
    background: $white-light;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    border: none;
    border-radius: rem(32);

    @include shadow-green-1();
    cursor: pointer;
    transition: 0.3s ease;

    &:hover {
      transform: scale(1.02);
    }

    &:focus {
      outline: none;
    }

    &:active {
      opacity: 0.8;
    }

    @media (min-width: 321px) and (max-width: 370px) {
      width: rem(150);
      padding: 0.5rem 1rem;
    }

    @media (max-width: 320px) {
      width: rem(130);
      padding: 0.3rem 0.5rem;
    }

    logo {
      margin-right: 0.5rem;
      height: rem(24);
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      small {
        color: $black-normal;
        font-weight: normal;
      }

      b {
        color: $black-normal;
      }
    }
  }
}
