ebarn-menu-mobile {
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
  }

  li {
    display: inline-block;
    color: $black-light;
    text-align: center;
    @include head(h3, desktop);
    padding: rem(16) rem(24);
    position: relative;
    user-select: none;

    &::after {
      content: '';
      position: absolute;
      top: 90%;
      left: rem(16);
      width: 0;
      height: 2px;

      transition: 0.5s ease;
      background-color: $secondary-light;
    }

    &:hover {
      opacity: 0.7;
      cursor: pointer;
      color: $secondary-light;

      &:after {
        width: 90%;
      }
    }
  }
}
