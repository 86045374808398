@use '@angular/material' as mat;
@import 'variables';

@mixin theme-reset($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  .bg-primary {
    background: mat.get-color-from-palette($primary);
    color: mat.get-color-from-palette($primary, default-contrast);
  }

  .bg-accent {
    background: mat.get-color-from-palette($accent);
    color: mat.get-color-from-palette($accent, default-contrast);
  }

  .bg-accent {
    background: mat.get-color-from-palette($warn);
    color: mat.get-color-from-palette($warn, default-contrast);
  }

  .secondary-text-color {
    color: mat.get-color-from-palette($foreground, secondary-text);
  }
  .primary-color {
    color: mat.get-color-from-palette($primary) !important;
  }
  .accent-color {
    color: mat.get-color-from-palette($accent) !important;
  }
  .warn-color {
    color: mat.get-color-from-palette($warn) !important;
  }

  .header {
    background: mat.get-color-from-palette($background, card);
    color: mat.get-color-from-palette($foreground, base);
    .info-content {
      .top-contact-form {
        border-color: mat.get-color-from-palette($foreground, divider);
      }
    }
  }
  .content-header h4 {
    color: mat.get-color-from-palette($primary, lighter);
  }
  .favorites-container:after {
    color: mat.get-color-from-palette($accent);
  }

  .menu-item {
    .mat-mdc-button {
      .menu-icon {
        background: mat.get-color-from-palette($background, focused-button);
        color: mat.get-color-from-palette($foreground, secondary-text);
      }
      .menu-title {
        color: mat.get-color-from-palette($foreground, text);
      }
      &.active-link {
        background: mat.get-color-from-palette($background, focused-button);
        .menu-icon {
          background: mat.get-color-from-palette($primary);
          color: #fff;
        }
      }
    }
  }
  .menu-expand-icon {
    color: mat.get-color-from-palette($foreground, secondary-text);
  }
  .breadcrumb a {
    color: mat.get-color-from-palette($primary, lighter);
  }
  .top-toolbar,
  .landing-toolbar {
    background: mat.get-color-from-palette($primary, default);
    color: mat.get-color-from-palette($primary, default-contrast);
  }
  .menu-toolbar {
    background: mat.get-color-from-palette($primary, 900);
    color: mat.get-color-from-palette($primary, 100);
  }

  .testimonial-item,
  .pricing-card {
    border-top: 4px solid mat.get-color-from-palette($accent);
  }

  /* ngx-charts */
  .ngx-charts {
    text {
      fill: mat.get-color-from-palette($foreground, text);
    }
  }
  [class*='gradient-'] .ngx-charts {
    text {
      fill: #fff;
    }
  }

  /* ngx-charts */
  .ngx-charts {
    text {
      fill: mat.get-color-from-palette($foreground, text);
    }
  }
}
