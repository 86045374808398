ebarn-card-servicos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @include layout-bp(lt-sm) {
    flex-direction: row;
    gap: 1rem;
  }

  cursor: pointer;

  &:hover {
    p {
      color: $primary-normal;
    }

    ebarn-card-icone {
      transform: scale(1.02);
    }
  }

  &:active {
    opacity: 0.7;
  }

  ebarn-card-icone,
  p {
    transition: 0.3s ease;
  }

  p {
    user-select: none;
    text-align: center;

    @include layout-bp(lt-sm) {
      text-align: left;
    }
  }

  p.body-1 {
    color: $gray-1;
    font-weight: 500;
    margin-top: 2rem;

    @include layout-bp(lt-sm) {
      margin-top: 0rem;
    }
  }

  p.body-3 {
    color: $gray-4;
    margin-top: 0.5rem;
  }

  .indicador {
    display: none;
    visibility: hidden;
    color: $gray-4;

    // @include layout-bp(lt-sm) {
    //   display: block;
    //   visibility: visible;
    // }
  }
}
