@import './../../../../src/app/theme/styles/unit';

@import './layout';
@import './cores';
@import './gradientes';
@import './tipografia';
@import './sombras';
@import './swiper';

svg,
img {
  display: block;
  //width: 100%;
}

@import './components/toolbar';
@import './components/mobile-menu';
@import './components/botoes';
@import './components/botao-loja';
@import './components/card-icone';
@import './components/card-depoimento';
@import './components/card-imprensa';
@import './components/card-artigo';
@import './components/card-perfil';
@import './components/card-servicos';
