ebarn-card-imprensa {
  display: block;

  border-radius: 32px;
  overflow: hidden;
  background-color: $white-light;
  @include shadow-green-1();

  p {
    padding: 1.5rem 1rem;
    text-align: center;
    color: $black-normal;
  }
}
